<template>
  <WithDimensions>
    <template #default="{ width }">
      <div>
        <CSkeleton v-if="loading && !documents.length" :height="80" />
        <div v-else-if="!documents.length" class="no-items-placeholder">
          <CIcon type="paper" stroke-width="1.75" size="15" />
          {{ $t('empty') }}
        </div>
        <div v-else>
          <DocumentInvoiceGroupItem
            v-for="(doc, i) in documentInstances"
            :id="doc.id"
            :key="i"
            :name="doc.name"
            :type="doc.type"
            :status="doc.status"
            :accepted-at="doc.acceptedAt"
            :has-denied-reasons="doc.hasDeniedReasons"
            :scheduled-first-delivery-date="doc.scheduledFirstDeliveryDate"
            :document-actions="doc.actions"
            :can-invoice="true"
            :is-compact="Boolean(width && width < 450)"
            class="document-list-group-item doc-invoice-row-base"
            @show-doc="showDocument(documents[i])"
          />
        </div>
      </div>
    </template>
  </WithDimensions>
</template>

<script>
import DocumentSendReminderModal from '@cling/components/document/form/send/reminders/DocumentSendReminderModal.vue'
import CSkeleton from '@cling/components/ui/Skeleton'
import WithDimensions from '@cling/components/WithDimensions.vue'
import windowSize from '@cling/mixins/windowSize'

import get from 'lodash/get'
import { mapActions, mapGetters } from 'vuex'

import DocumentInvoiceGroupItem from '@/components/account/document/DocumentInvoiceGroupItem.vue'
import DocumentPrintModal from '@/components/document2/DocumentPrintModal.vue'
import { global } from '@/store/action-types'

export default {
  i18nOptions: {
    namespaces: 'components',
    keyPrefix: 'account.document.invoice'
  },
  name: 'DocumentInvoiceList',
  components: {
    DocumentInvoiceGroupItem,
    WithDimensions,
    CSkeleton
  },
  mixins: [windowSize],
  props: {
    documents: {
      type: Array,
      required: true
    },
    simpleActions: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      projectById: 'projects/byId'
    }),
    documentInstances() {
      let { documents } = this

      documents = documents.map(doc => {
        const {
          id,
          name,
          type = 'document',
          scheduledFirstDelivery,
          rejectReasons,
          createdAt,
          acceptedAt,
          prices
        } = doc
        let { status } = doc
        let statusText = this.$t(`_common:status.${status}`) || ''

        // Calculate if there is any denied reasons
        const hasDeniedReasons = !!(
          status === 'denied' &&
          rejectReasons &&
          rejectReasons.length
        )

        // Is there a scheduled first delivery?
        let scheduledFirstDeliveryDate = null
        if (
          scheduledFirstDelivery &&
          scheduledFirstDelivery.status === 'active'
        ) {
          scheduledFirstDeliveryDate = scheduledFirstDelivery.sendAt
          status = 'scheduled'
          statusText = this.$t('_common:status.scheduled')
        }

        return {
          createdAt,
          name,
          statusText,
          status,
          id,
          type,
          hasDeniedReasons,
          scheduledFirstDeliveryDate,
          acceptedAt:
            acceptedAt && doc.status === 'accepted'
              ? this.$formatDate(acceptedAt)
              : '',
          actions: this.documentActions(doc),
          isAccepted: doc.status === 'accepted',
          totalAmount: prices && prices.total ? prices.total : 0
        }
      })

      return documents
    }
  },
  methods: {
    ...mapActions({
      deleteDocument: global.DELETE_DOCUMENT2,
      updateDocument: global.UPDATE_DOCUMENT2,
      duplicateDocument: global.LOAD_DUPLICATE_DOCUMENT2_FORM,
      handleDocumentEdit: global.HANDLE_DOCUMENT2_EDIT,
      showMessage: global.SHOW_MESSAGE,
      setForm: global.SET_FORM
    }),
    /**
     * Get array of document actions
     */
    documentActions(doc, { showRemove = true } = {}) {
      const {
        id,
        projectId,
        status,
        sourceDocument,
        publicId,
        data,
        events = [],
        clients = []
      } = doc

      const sentClientIds = events
        .filter(({ code, data }) => code === 'sent' && data?.clients?.length)
        .flatMap(({ data }) => data.clients.map(({ id }) => id))

      const isAccepted = status === 'accepted'
      const { documents } = this.projectById(projectId) || {}
      const isAnswerMethodButton =
        get(data, 'defaultAnswerMethod.accept', null) === 'button'
      const isAnswerMethodSignature =
        get(data, 'defaultAnswerMethod.accept', null) === 'signature'

      const canEditDocument = doc.can('modify')
      const canUpgradeAnswerMethod =
        canEditDocument &&
        isAccepted &&
        (isAnswerMethodButton || isAnswerMethodSignature)
      const canDeleteDocument =
        doc.can('delete') && Array.isArray(documents) && documents.length > 1
      const canSendReminder =
        doc.can('modify') &&
        clients.some(({ id }) => sentClientIds.includes(id))
      const canDuplicate = doc.can('modify')

      const actions = []

      if (this.simpleActions) {
        // Go to project
        actions.push({
          name: 'showProject',
          icon: 'folder',
          title: this.$t('actions.showProject'),
          routerTo: { name: 'project', params: { id: projectId } }
        })
        return actions
      }

      // Edit
      actions.push({
        name: 'edit',
        icon: 'edit',
        title: this.$t('actions.edit'),
        onClick: async () => {
          await this.handleDocumentEdit({ documentId: id })
        },
        disabled: !canEditDocument
      })

      // Reminder
      actions.push({
        name: 'sendReminder',
        icon: 'bell',
        title: this.$t('actions.sendReminder'),
        onClick: () => {
          this.$modal.show(
            DocumentSendReminderModal,
            {
              document: doc
            },
            {
              adaptive: true,
              height: 'auto',
              width: '90%',
              maxWidth: 400,
              scrollable: true,
              classes: 'overflow-visible primary'
            }
          )
        },
        disabled: !canSendReminder,
        tooltip: !canSendReminder
          ? this.$t('actions.sendReminderTooltip')
          : null
      })

      // Duplicate document
      if (!sourceDocument) {
        actions.push({
          name: 'duplicate',
          icon: 'copy',
          title: this.$t('actions.duplicate'),
          onClick: async () => {
            await this.duplicateDocument({ id })
            this.$router.push({ name: 'documentForm' })
          },
          disabled: !canDuplicate
        })
      }

      // Print
      if (doc.can('readPrice') && publicId) {
        actions.push({
          name: 'print',
          icon: 'print',
          title: this.$t('_common:download', { thing: 'PDF' }),
          onClick: () => this.$modal.show(DocumentPrintModal, { document: doc })
        })
      }

      if (
        (isAnswerMethodButton || isAnswerMethodSignature) &&
        this.$feature('upgradeSignatureBankId')
      ) {
        actions.push({
          name: 'upgradeSignature',
          icon: 'pen-tool',
          title: this.$t('actions.upgradeSignature'),
          onClick: () => {
            this.upgradeSignatureBankId(doc)
          },
          disabled: !canUpgradeAnswerMethod
        })
      }

      // Remove
      if (canDeleteDocument && showRemove) {
        actions.push({
          name: 'remove',
          icon: 'trash',
          type: 'danger',
          title: this.$t('actions.remove'),
          onClick: () => {
            this.showMessage({
              displayType: 'dialog',
              type: 'danger',
              title: this.$t('actions.removeDialog.title'),
              message: this.$t('actions.removeDialog.message'),
              actions: {
                cancel: {
                  text: this.$t('actions.removeDialog.cancel'),
                  callback: 'cancel'
                },
                submit: {
                  text: this.$t('actions.removeDialog.submit'),
                  callback: () => {
                    this.deleteDocument({ id })
                    this.$emit('close')
                  }
                }
              }
            })
          }
        })
      }

      return actions
    },
    async showDocument(doc) {
      if (!doc) throw Error('Missing param doc')

      this.$router.push({ name: 'document', params: { id: doc.id } })

      return true
    },
    /**
     * Change sign method to bankId, remove any answer, and send to edit
     */
    async upgradeSignatureBankId(doc) {
      const newDocument = doc.clone()
      newDocument.resetReminders()
      newDocument.setAnswerMethod({ accept: 'bankId' })

      newDocument.docNumber = { series: 'contract' }
      newDocument.setTag('category', 'contract')
      const formData = newDocument.getRawData()

      formData.clearAnswerBeforePut = true // Flag used on PUT

      await this.setForm({ key: 'document2', formData })
      this.$router.push({ name: 'documentForm', params: { id: doc.id } })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@cling/styles/main.scss';

.doc-invoice-row-base {
  + .doc-invoice-row-base {
    margin-top: 1.25rem;
  }
}
</style>
