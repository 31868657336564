import config from '@cling/config'
import lang from '@cling/language'
import { brands } from '@cling/static'
import {
  addArticleTotalAmount,
  articleHasHouseWork,
  articleHouseWorkType,
  flattenObject,
  omitDeep,
  roundPriceNested
} from '@cling/utils'

import * as jsonLogic from 'json-logic-js/logic'
import get from 'lodash/get'
import isPlainObject from 'lodash/isPlainObject'
import set from 'lodash/set'

/**
 * Helper to compress document 'viewed' events into groups/user groups
 *
 * @param {Array} array An array with elements based on document events
 *
 * @returns {Array} Returns array with 'viewed' events with same client id grouped in between important events
 */
export function groupSelectedEventBetweenSent(array, selectedEvent) {
  let startIndex = 0
  const result = []

  for (const [i, event] of array.entries()) {
    const { code: currCode, data: currData } = event
    if (currCode === selectedEvent) {
      const first = result
        .slice(startIndex)
        .find(({ code: firstCode, data: firstData }) => {
          if (firstCode !== currCode) return false

          const firstClientId = get(firstData, 'client.id')
          const currClientId = get(currData, 'client.id')

          const firstEmail = get(firstData, 'email')
          const currentEmail = get(currData, 'email')

          return (
            (firstClientId === currClientId && currClientId !== undefined) ||
            (firstEmail === currentEmail && currentEmail !== undefined)
          )
        })
      if (first) {
        first.children = [...first.children, event]
        // set last event as show date
        first.createdAt = event.createdAt
      } else result.push({ ...event, children: [] })
    } else {
      if (currCode === 'sent') startIndex = i ? i - 1 : 0
      result.push(event)
    }
  }
  return result
}

export const isVisibleOnlyNodes = ({ nodes, data }) => {
  const result = []
  nodes.forEach(a => {
    let allowAdd = a.isVisible === undefined

    if (!allowAdd) {
      // if isVisible is defined
      // evaluate json logic if object else treat as boolean
      // Remove any private variables from isVisible as any unused props such as _uniqueId would make the rules fail
      allowAdd =
        a.isVisible && typeof a.isVisible === 'object'
          ? jsonLogic.apply(omitDeep(a.isVisible, ['_uniqueId']), data)
          : !!a.isVisible
    }

    if (allowAdd) result.push(a)
    if (a && Array.isArray(a.children) && a.children.length) {
      a.children = isVisibleOnlyNodes({ nodes: a.children, data })
    }
  })
  return result
}

// Must be in sync with backend houseWorkMap
const houseWorkMap = [
  // Add possible new updates here
  {
    cutoffDate: new Date('2025-01-01'),
    constants: {
      rot: { max: 50000 * 100, ratio: 0.3 },
      rut: { max: 75000 * 100, ratio: 0.5 },
      greenRot15: { max: 50000 * 100, ratio: 0.15 },
      greenRot20: { max: 50000 * 100, ratio: 0.2 },
      greenRot50: { max: 50000 * 100, ratio: 0.5 }
    }
  },
  {
    cutoffDate: new Date('2024-07-01'),
    constants: {
      rot: { max: 75000 * 100, ratio: 0.3 },
      rut: { max: 75000 * 100, ratio: 0.5 },
      greenRot15: { max: 50000 * 100, ratio: 0.15 },
      greenRot20: { max: 50000 * 100, ratio: 0.2 },
      greenRot50: { max: 50000 * 100, ratio: 0.5 }
    }
  },
  {
    cutoffDate: null,
    constants: {
      rot: { max: 50000 * 100, ratio: 0.3 },
      rut: { max: 75000 * 100, ratio: 0.5 },
      greenRot15: { max: 50000 * 100, ratio: 0.15 },
      greenRot20: { max: 50000 * 100, ratio: 0.2 },
      greenRot50: { max: 50000 * 100, ratio: 0.5 }
    }
  }
]

const getHouseWorkConstants = date => {
  const currentRules = houseWorkMap.filter(
    ({ cutoffDate }) => cutoffDate <= new Date()
  ) // Filter future rules
  if (!date) return currentRules[0]?.constants // Return constants of the first rule if no date is provided

  return currentRules.find(
    ({ cutoffDate }) => !cutoffDate || new Date(date) >= cutoffDate
  ).constants
}

/**
 * Get correct houseWorkAmount (deduction) based on:
 * type, total work (with deduction) and number of clients
 *
 * @param {String} type houseWork type
 * @param {Number} workValue total work amount (eligible for deduction)
 * @param {Number} noOfClients number of clients associated with the deduction
 * @param {DateString} deductionDate cutoff date for deduction constants
 *
 * @returns {Number} Returns applicable deduction
 */
const getHouseWorkAmount = (type, workValue, noOfClients, deductionDate) => {
  const constants = getHouseWorkConstants(deductionDate)
  if (!constants[type]) throw new Error('Missing param type')
  const { max, ratio } = constants[type]
  return Math.min(noOfClients * max, workValue * ratio)
}

/**
 * Calculates the prices for a document
 * Supports different params to change the behaviour of the calculation.
 * If anything changes here make sure to change in both Vue/API as this function is used in both environments
 *
 * @param {Object} obj
 * @param {String} obj.type The priceType
 * @param {String} obj.currency Optional default currency
 * @param {Object[]} obj.articles Array of articles
 * @param {Object[]} obj.clients Array of clients
 * @param {Object} obj.region
 * @param {Number} obj.maxTotal Optional totalPrice
 * @param {Boolean} obj.useVat If vat is enabled
 * @param {Object} obj.rounding Obj how rounding should be handled
 * @param {Boolean} obj.rounding.enabled If the price should be rounded
 * @param {Number} obj.rounding.interval Round to nearest inteval, example 1: neareset cent (no rounding), 50: nearest 50 cent, 100: nearest 100 cent
 * @param {Boolean} obj.rounding.show If the rounding should be visible to user
 * @returns {Object} Returns priceObject { type, subTotal, taxAmount, roundingAmount, total, currency, maxTotal, region, helpers, rounding }
 */
export const calcPrices = ({
  type = 'fixed',
  currency: currencyParam,
  maxTotal = null,
  articles = [],
  clients = [],
  region = {},
  useVat,
  rounding
}) => {
  if (typeof useVat === 'undefined') throw new Error('Missing param useVat')
  if (typeof useVat !== 'boolean')
    throw new Error('Param useVat must be a boolean')
  if (typeof rounding !== 'object' || rounding === null)
    throw new Error('Param rounding must be a object')

  if (typeof rounding.enabled === 'undefined')
    throw new Error('Missing param rounding.enabled')
  if (typeof rounding.enabled !== 'boolean')
    throw new Error('Param rounding.enabled must be a boolean')
  if (typeof rounding.interval === 'undefined')
    throw new Error('Missing param rounding.interval')
  if (typeof rounding.interval !== 'number')
    throw new Error('Param rounding.interval must be a number')
  if (typeof rounding.show === 'undefined')
    throw new Error('Missing param rounding.show')
  if (typeof rounding.show !== 'boolean')
    throw new Error('Param rounding.show must be a boolean')

  // Object to return
  const prices = {
    type,
    subTotal: 0,
    taxAmount: 0,
    roundingAmount: 0,
    total: 0,
    currency: currencyParam,
    maxTotal: type === 'openAccMaxPrice' ? maxTotal || 0 : null,
    region: {
      country: 'sweden',
      houseWorkType: null,
      reverseVat: !!clients.find(i => get(i, 'region.reverseVat')),
      houseWorkManualAmount: region.houseWorkManualAmount || null,
      houseWorkWork: 0,
      houseWorkAmount:
        region.houseWorkManualAmount || region.houseWorkAmount || null,
      deductionDate: region.deductionDate || null
    },
    helpers: {
      discount: 0,
      isIndivid: !clients.find(i => i.type === 'company'),
      noOfClients: clients.length || 1
    },
    useVat,
    rounding: {
      enabled: rounding.enabled,
      interval: rounding.interval,
      show: rounding.show
    }
  }

  const vatDecimal = integer => integer / 100

  // Set housework type if allowed
  if (prices.helpers.isIndivid && !prices.region.reverseVat) {
    // Select houseWork from last updated articles to decide which housework type to use
    const art = [...articles]
      .sort(
        (a, b) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      )
      .find(articleHasHouseWork)
    // If houseWorkType has already been set, skip this step
    if (art && !prices.region.houseWorkType)
      prices.region.houseWorkType = articleHouseWorkType(art)
  }

  // Calculate price sums
  for (let art of articles) {
    art = addArticleTotalAmount(art)
    const { totalAmount, currency, priceType, discount, discountType } = art
    let { vat } = art
    if (vat === null) vat = 0
    if (!useVat) vat = 0

    if (!['fixed', 'approx'].includes(priceType)) continue

    prices.currency = currency
    prices.subTotal += totalAmount
    prices.taxAmount += totalAmount * vatDecimal(vat)

    prices.helpers.discount +=
      discountType === 'percentage'
        ? totalAmount / ((100 - discount / 100) / 100) - totalAmount
        : discount

    if (prices.region.houseWorkType && articleHasHouseWork(art)) {
      prices.region.houseWorkWork += totalAmount * ((100 + vat) / 100)
    }
  }

  // Reverse VAT
  if (!prices.helpers.isIndivid && prices.region.reverseVat)
    prices.taxAmount = 0

  // Calculate ROT/RUT depending on client
  if (prices.region.houseWorkType && prices.region.houseWorkAmount === null) {
    prices.region.houseWorkAmount = getHouseWorkAmount(
      prices.region.houseWorkType,
      prices.region.houseWorkWork,
      prices.helpers.noOfClients,
      prices.region.deductionDate
    )
  }

  const totalBeforeRounding =
    prices.subTotal + prices.taxAmount - prices.region.houseWorkAmount

  prices.roundingAmount =
    rounding.enabled && rounding.interval
      ? Math.round(totalBeforeRounding / rounding.interval) *
          rounding.interval -
        totalBeforeRounding
      : 0

  prices.total = totalBeforeRounding + prices.roundingAmount

  roundPriceNested(prices)
  return prices
}

// Synced with identical util method in cling-api
export const aggregateDocumentPrices = documentJSON => {
  if (!documentJSON) return null

  const {
    currency,
    clients = [],
    articles: allArticles = [],
    acceptedAt
  } = documentJSON
  const packageGroups = get(documentJSON, 'data.packageGroups', {})

  const useVat = get(documentJSON, 'data.formSettings.useVat', true)

  const rounding = {
    enabled: get(documentJSON, 'data.formSettings.rounding.enabled', false),
    interval: get(documentJSON, 'data.formSettings.rounding.interval', 1),
    show: get(documentJSON, 'data.formSettings.rounding.show', true)
  }

  const priceTypes = []
  const activePackageIds = []
  let maxTotalSum = null
  let houseWorkManualAmountSum = null
  let houseWorkAmountSum = null

  Object.keys(packageGroups).forEach(gId => {
    const selectedPackages = packageGroups[gId].packages.filter(
      ({ isSelected }) => isSelected
    )
    activePackageIds.push(...selectedPackages.map(({ packageId }) => packageId))

    const priceType = get(selectedPackages, '[0].data.prices.type', 'fixed')
    priceTypes.push(...(Array.isArray(priceType) ? priceType : [priceType]))

    selectedPackages.forEach(p => {
      const { maxTotal = null, region } = get(p, 'data.prices') || {}

      if (
        priceType === 'openAccMaxPrice' &&
        !Number.isNaN(Number.parseInt(maxTotal, 10))
      ) {
        maxTotalSum += maxTotal
      }
      if (
        region &&
        !Number.isNaN(Number.parseInt(region.houseWorkManualAmount, 10))
      ) {
        houseWorkManualAmountSum += region.houseWorkManualAmount
      }
    })
  })

  // Failsafe for legacy docs (won't cause unwanted changes forward)
  if (!priceTypes.length) {
    const pType = get(documentJSON, 'data.prices.type', 'fixed')
    priceTypes.push(...(Array.isArray(pType) ? pType : [pType]))
  }

  const articles = allArticles.filter(
    ({ packageId }) => !packageId || activePackageIds.includes(packageId)
  )

  // Aggregated houseWork deductions
  const houseWorkArticles = articles.filter(articleHasHouseWork)
  const houseWork = {}

  for (let art of houseWorkArticles) {
    art = addArticleTotalAmount(art)

    if (!['fixed', 'approx'].includes(art.priceType)) continue
    const hwType = articleHouseWorkType(art)
    const vat = useVat ? art.vat : 0
    if (!houseWork[hwType]) houseWork[hwType] = null
    houseWork[hwType] += art.totalAmount * ((100 + vat) / 100)
  }

  for (const [hwType, hwValue] of Object.entries(houseWork)) {
    if (hwValue)
      houseWorkAmountSum += getHouseWorkAmount(
        hwType,
        hwValue,
        clients.length || 1,
        acceptedAt
      )
  }

  // PriceType and maxTotal intentionally avoided in calcPrices and added after the fact
  // since this is a cohort of all group prices
  return {
    ...calcPrices({
      currency,
      articles,
      clients,
      region: {
        country: 'sweden',
        houseWorkManualAmount: houseWorkManualAmountSum,
        houseWorkAmount: houseWorkAmountSum,
        deductionDate: acceptedAt
      },
      useVat,
      rounding
    }),
    type: priceTypes,
    maxTotal: maxTotalSum
  }
}

export const getVirtualFields = doc => {
  const t = key => lang.t(`_common:documentMentions.${key}`)
  const clientExists = !!get(doc, 'clients.0')

  const fields = {
    clientName: {
      key: 'clients.0.name',
      meta: { inputDisabled: !clientExists, virtual: true }
    },
    clientEmail: {
      key: 'clients.0.email',
      meta: { inputDisabled: !clientExists, virtual: true }
    },
    clientPhone: {
      key: 'clients.0.cellphone',
      meta: { inputDisabled: !clientExists, virtual: true }
    },
    clientCompany: {
      key: 'clients.0.companyName',
      meta: { inputDisabled: !clientExists, virtual: true }
    },
    clientAddress: {
      key: 'clients.0.addresses.standard.street',
      meta: { inputDisabled: !clientExists, virtual: true }
    },
    clientZip: {
      key: 'clients.0.addresses.standard.zip',
      meta: { inputDisabled: !clientExists, virtual: true }
    },
    clientCity: {
      key: 'clients.0.addresses.standard.city',
      meta: { inputDisabled: !clientExists, virtual: true }
    },
    clientSocialNo: {
      key: 'clients.0.socialNo',
      meta: { inputDisabled: true, virtual: true }
    },
    clientOrgNo: {
      key: 'clients.0.orgNo',
      meta: { inputDisabled: true, virtual: true }
    },
    senderName: {
      key: 'companyUser.fullName',
      meta: { inputDisabled: true, virtual: true }
    },
    senderEmail: {
      key: 'companyUser.email',
      meta: { inputDisabled: true, virtual: true }
    },
    senderPhone: {
      key: 'companyUser.cellphone',
      meta: { inputDisabled: true, virtual: true }
    },
    senderCompany: {
      key: 'company.name',
      meta: { inputDisabled: true, virtual: true }
    },
    senderAddress: {
      key: 'company.street',
      meta: { inputDisabled: true, virtual: true }
    },
    senderZip: {
      key: 'company.zip',
      meta: { inputDisabled: true, virtual: true }
    },
    senderCity: {
      key: 'company.city',
      meta: { inputDisabled: true, virtual: true }
    },
    documentName: {
      key: 'data.name',
      meta: { inputDisabled: false, virtual: true }
    },
    sentAt: {
      key: 'sentAt',
      filter: 'formatDate',
      meta: { inputDisabled: true, virtual: true }
    },
    expiresAt: {
      key: 'data.expiresAt',
      filter: 'formatDate',
      meta: { inputDisabled: true, virtual: true }
    }
  }

  for (const label of Object.keys(fields)) {
    fields[label].label = t(label)
    const value = get(doc, fields[label].key) || ''

    fields[label].value =
      fields[label].filter === 'formatDate' ? lang.formatDate(value) : value
  }

  return fields
}

export const findClientPathByPublicDocumentId = ({
  publicDocumentId,
  clients = [],
  isSender = false
}) => {
  if (!publicDocumentId) throw new Error('Missing param publicDocumentId')
  if (isSender) return 'senderClient'

  const index = clients.findIndex(c => c.publicDocumentId === publicDocumentId)
  return index !== -1 ? `clients[${index}]` : ''
}

export const filterEmptyProps = obj => {
  if (!obj) return {}
  return Object.keys(obj).reduce(
    (res, key) =>
      obj[key] || obj[key] === false ? { ...res, [key]: obj[key] } : res,
    {}
  )
}

const concatPath = (path, property) => {
  if (property && property.toString) {
    if (path) path += '.'
    path += property.toString()
  }
  return path
}

/**
 * Gives the ability to run a callback function
 * when changes are made to the provided object
 *
 * @param {Object} obj
 * @param {Function} callback function to run when changes are made to the object
 */
export const onChange = (obj, callback) => {
  const pathCache = new WeakMap()
  const proxyCache = new WeakMap()

  const handleChange = (path, property, previous, value) => {
    callback(concatPath(path, property), value, previous)
  }
  const buildProxy = (value, path) => {
    pathCache.set(value, path)
    let proxy = proxyCache.get(value)
    if (proxy === undefined) {
      proxy = new Proxy(value, handler)
      proxyCache.set(value, proxy)
    }
    return proxy
  }
  const handler = {
    get(target, property, receiver) {
      const value = Reflect.get(target, property, receiver)
      if (
        value === null ||
        typeof value !== 'object' ||
        property === 'constructor'
      )
        return value
      return buildProxy(value, concatPath(pathCache.get(target), property))
    },
    set(target, property, value, receiver) {
      const previous = Reflect.get(target, property, receiver)
      handleChange(pathCache.get(target), property, previous, value)
      return Reflect.set(target, property, value)
    }
  }

  const proxy = buildProxy(obj, '')
  callback = callback.bind(proxy)
  return proxy
}

// Function to setup the formSettings object for a document instance
export function setupFormSettings(doc) {
  if (!doc || !doc.data) return doc

  const isRegionSE = brands[config.brand].defaultRegion === 'SE'

  const formSettings = {
    useVat: isRegionSE,
    vatType: 'vat', // Maybe use company region, but as US users would have vat disabled it might not be needed now
    rounding: {
      enabled: isRegionSE,
      interval: isRegionSE ? 100 : 1,
      show: isRegionSE
    }
  }

  if (!doc.data.formSettings) doc.data.formSettings = {}

  const flatFormSettings = flattenObject(formSettings)
  Object.keys(flatFormSettings).forEach(key => {
    // Recursivly set values, iterate over object/arrays to only overwrite primative data types to not cause loops (vue)
    function setValue(obj, key, value) {
      if (isPlainObject(obj[key])) {
        Object.keys(obj[key]).forEach(subKey => {
          setValue(obj[key], subKey, value)
        })
      } else if (Array.isArray(obj[key])) {
        obj[key].forEach((subObj, index) => {
          setValue(obj[key], index, value)
        })
      } else {
        // Only set default when there was no value present
        if (typeof get(obj, key) === 'undefined') set(obj, key, value)
      }
    }
    setValue(doc.data.formSettings, key, flatFormSettings[key])
  })

  return doc
}

export default {
  groupSelectedEventBetweenSent,
  isVisibleOnlyNodes,
  calcPrices,
  findClientPathByPublicDocumentId,
  filterEmptyProps,
  onChange,
  setupFormSettings
}
